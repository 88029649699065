import React, { useState, useEffect } from 'react';
import { db  } from './firebase';
import { collection, query, where, getDocs} from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faCrown, faFire,faGem,faStopwatch } from '@fortawesome/free-solid-svg-icons';
import logo from './8.png';
import './BusinessFormV2.css';


const priceCards = [
    {
      id:1,
      title: '1 Day',
      price: '15',
      packageName:'Basic',
      subDes:'Quick Exposure',
      icon:<FontAwesomeIcon icon={faFire} style={{color: "#ffffff",fontSize:'1.3rem'}} />
    },
    {
      id:2,
      title: '7 Days',
      price: '100',
      packageName:'Professional',
      subDes:'Recommended',
      icon:<FontAwesomeIcon icon={faBolt} style={{color: "#ffffff",fontSize:'1.3rem'}} />
    },
    {  
      id:3,
      title: '14 Days',
      price: '180',
      packageName:'Diamond',
      subDes:'Best Value',
      icon:<FontAwesomeIcon icon={faGem} style={{color: "#ffffff",fontSize:'1.3rem'}} />
    },
    {
      id:4,
      title: '30 Days',
      price: '350',
      packageName:'Ultimate',
      subDes:'Maximum Impact',
      icon:<FontAwesomeIcon icon={faCrown} style={{color: "#ffffff",fontSize:'1.3rem'}} />
    },
  ];


const MyForm = () => {
  const [options, setOptions] = useState([]); // For dropdown options
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null); // State for storing the selected option object
  const [answer, setAnswer] = useState('');

  // Firestore data retrieval for dropdown
  useEffect(() => {
    fetchApprovedBusinesses();
  }, []);

  //getting the businesses which are approved
  const fetchApprovedBusinesses = async () => {
    try {
      const businessesCol = collection(db, 'Businesses', 'common', 'businesses');
      const q = query(businessesCol, where('approved', '==', true));

      const querySnapshot = await getDocs(q);
      const businesses = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log(businesses);
      setOptions(businesses);
    } catch (error) {
      console.error("Error fetching approved businesses: ", error);
    }
  };

  const handleDropdownChange = (event) => {
    const selectedId = event.target.value;
    const option = options.find(opt => opt.id === selectedId);
    
    setSelectedBusiness(option); // Now you have the full selected option object
  };


   // Handler function
  const handleAnswer = (userChoice) => {
    // console.log(userChoice); // Logs 'Yes' or 'No'
    // setAnswer(userChoice); // Optional: Updates the state
    if(userChoice==='Yes') {
        setAnswer(true);
    }else{
      setAnswer(false);
    }
  };

  // Handling form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Using FormData to get form values
    const formDatas = new FormData(e.target);

    const formValues = {
      Name: formDatas.get('Name'), // 'Name' is the name attribute of your select input
      BusinessPhone: formDatas.get('BusinessPhone'), // Ensure name attributes match
      selectedCard: selectedCard, // Handling selected card separately
      BusinessProviderName:formDatas.get('BusinessProviderName')
    };
    

    const durationInDays = parseInt(formValues.selectedCard.title.replace(/\D/g,'')); // Remove non-digit characters

    const startDate = new Date();

    const endDate = new Date();
    endDate.setDate(startDate.getDate() + durationInDays);

    const finalData={
        Name:formValues.Name,
        BusinessProviderName:formValues.BusinessProviderName,
        BusinessPhone: formValues.BusinessPhone,
        Duration:durationInDays,
        Pay:formValues.selectedCard.price,
        StartDate:startDate,
        EndDate:endDate,
    };


    fetch('http://localhost:4000/create-checkout-session',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(finalData ),
    })
    .then(response => response.json())
    .then(data => {
        console.log(data); // Log the response
        if (data.url) {
            window.location.href = data.url; // Redirect to Stripe Checkout
        } else {
            console.error('No URL received');
        }
    })
  };


  function isMobile() {
    // User agent strings to check
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Regular expression for mobile devices
    const mobileRegex = /iPhone|iPod|android|blackberry|mini|windows\sce|palm/i;
  
    // Check if the user agent string matches mobile devices
    return mobileRegex.test(userAgent);
  }
  
  // const result = isMobile();
  // if(!result){
  //   return (
  //     <div style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
  //     <h1 style={{color:'red',padding:'30px',border:'4px solid #113838',borderRadius:'30px'}}>This is only available in mobile devices</h1>
  //     </div>
  //   );
  // }


  return (
    <div className="business-form">
    <div className="header">
      <img src={logo} width={100} alt=""/>
    </div>
    <form className='form' onSubmit={handleSubmit}>
    <h1>Business Information</h1>

    <div id='asking-container'>
      <div className='name-container'>
        <select name="Name" onChange={handleDropdownChange} required>
        <option value="" disabled selected hidden>Please select business</option>
          {options.map(option => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </select>
      </div>

      <div className="question-container">
              <h2>Are you the owner of the business?</h2>
              <div className="btn">
                <button type='button' onClick={() => handleAnswer('Yes')}>Yes</button>
                <button type='button' onClick={() => handleAnswer('No')}>No</button>
              </div>
      </div>
      
    </div>

    <div className='input-container'>
      <label htmlFor="">Name</label>
      <input  value={answer?selectedBusiness.Name:''} required name="BusinessProviderName" type='text' />    
    </div>

    <div className='input-container'>
      <label htmlFor="">Phone Number</label>
      <input value={answer?selectedBusiness.phone:''} required name="BusinessPhone" type="tel"  />    
    </div>

    <div className='input-container'>
      <label htmlFor="">Email</label>
      <input value={answer?selectedBusiness.email:''} required name="BusinessEmail" type="email" />  
    </div>

    <div className="card-container">
      <h2 style={{textAlign:'left',marginLeft:'5px'}}>Plans <span style={{color:'#113838'}}>: {selectedCard?.packageName}</span></h2>
      {priceCards.map(card => (
        <div key={card.id} className={`card ${selectedCard?.id === card.id ? 'selected' : ''}`} onClick={() => setSelectedCard(card)}>
        <div className='info-container'>
          <div>
            {
              card.icon
            }
          </div>
          <div>
            <h2>{card.packageName}</h2>
          </div>
          <span>
            {card.subDes}
          </span>
        </div>
        <div className='price-container'>
        <div className='price'>${card.price}</div>
        <div style={{fontWeight:'lighter',fontSize:'12px'}}><FontAwesomeIcon icon={faStopwatch} /> {card.title}</div>
        </div>
          
          
        </div>
      ))}
      <button type="submit" className='submit-button'>Continue To Payment</button>
    </div>
    </form>
    </div>
  );
};

export default MyForm;





