import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
    apiKey: "AIzaSyDC4CSwXoGDYLmuyuOh690Jpv2D9LUHaVQ",
    authDomain: "practice-7a1fe.firebaseapp.com",
    projectId: "practice-7a1fe",
    storageBucket: "practice-7a1fe.appspot.com",
    messagingSenderId: "372999904532",
    appId: "1:372999904532:web:e395a6b65ac12067c4b601"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);

//change change change