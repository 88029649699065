import './App.css';
import BusinessFormV2 from './BuinessFormV2';

function App() {
  return (
    <div style={{}}>
      <BusinessFormV2/>
    </div>
  );
}

export default App;
